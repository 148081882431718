import React, { memo, useEffect, useMemo, useState } from 'react';

import { Skeleton } from 'primereact/skeleton';
import { useFormContext } from 'react-hook-form';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface Props {
  name: string;
  label?: string;
  className?: string;
  profissionais?: any[];
  conveniosListAPI: any;
  setConveniosListAPI: any;
  multiple?: boolean;
}

const FieldConvenio = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    multiple = true,
    name,
    profissionais,
    label,
    className,
    conveniosListAPI,
    setConveniosListAPI,
  } = props;

  const { control, watch, setValue } = useFormContext();
  const { user } = useAppSelector((state: RootState) => state);

  const isMedico = user?.tipoProfissionalSaude === 'MEDICO';

  const idsProfissionais =
    (profissionais ?? []).length > 0
      ? profissionais?.map(profissional => profissional.id)
      : [];

  const watchProfissional = watch('profissionais');

  useMemo(() => {
    const loadConvenios = async () => {
      if (isMedico) {
        setLoading(true);
        const response = await ConvenioAPI.loadConveniosByProfissional(
          user?.idUsuario,
        );
        if (multiple) {
          response.unshift({ id: -1, razaoSocial: 'Todos' });
        }
        setConveniosListAPI(response);
        setLoading(false);
        return;
      }

      setLoading(true);

      if (watchProfissional?.length > 0) {
        const arrayProfissionais =
          watchProfissional[0] === -1 ? idsProfissionais : watchProfissional;

        const response = await ConvenioAPI.getConveniosMultiplosProfissionais({
          idProfissionais: arrayProfissionais
            ?.filter((id: any) => id !== -1)
            .toString(),
        });
        if (multiple) {
          response.unshift({ id: -1, razaoSocial: 'Todos' });
        }
        setConveniosListAPI(response);
      }
      setLoading(false);
      return;
    };

    loadConvenios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMedico, user?.idUsuario, watchProfissional]);

  if (loading) {
    return (
      <div className={className}>
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          {label}
        </SimpleText>
        <Skeleton className="p-mt-2" height="33px" borderRadius="8px" />
      </div>
    );
  }

  if (!multiple)
    return (
      <DropdownControlled
        control={control}
        name={name || 'convenio'}
        label={label}
        options={conveniosListAPI || []}
        optionLabel="razaoSocial"
        optionValue="id"
        placeholder="Selecione um convênio"
      />
    );

  return (
    <div className={className}>
      <MultiSelectControlled
        name={name || 'convenios'}
        label="Convênios"
        display={'chip'}
        filter
        showSelectAll
        options={conveniosListAPI || []}
        optionLabel="razaoSocial"
        optionValue="id"
        disabled={!watchProfissional?.length}
        control={control}
      />
    </div>
  );
};

export default memo(FieldConvenio);
