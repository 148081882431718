import { useEffect, useState, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import { PesquisaPacienteAPI } from 'src/APIs/AgendaAPI/PesquisaPacienteAPI/PesquisaPacienteAPI';
import CentroCustoAPI from 'src/APIs/FinanceiroAPI/CentroCustoAPI/CentroCustoAPI';
import ServicoTussValorAPI from 'src/APIs/FinanceiroAPI/ServicoTussValor/ServicoTussValorAPI';
import { TipoContaReceberAPI } from 'src/APIs/FinanceiroAPI/TipoContaReceberAPI/TipoContaReceberAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import ConsultorioMultiSelect from '../../../../components/ConsultorioMultiSelect';
import FieldConvenio from '../../../../components/FieldConvenio';
import FieldProfissionais from '../../../../components/FieldProfissionais';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import MultipleCheckboxControlled from 'src/components/Basics/Checkbox/MultipleCheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { FieldSearchPaciente } from 'src/components/Fields/FieldSearchPacientes/FieldSearchPacientes';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import { useSelectedContaReceber } from '../../../useSelectedContaReceber';

const optionsStatus = [
  { label: 'A receber', value: 'A_RECEBER' },
  { label: 'Recebido', value: 'RECEBIDO' },
  { label: 'Parcialmente recebido', value: 'PARCIALMENTE_RECEBIDO' },
];
const idTodos = -1;

interface PesquisaAvancadaProps {
  onSuccess?: () => void;
  consultoriosList?: any[];
}

export const PesquisaAvancada = ({
  onSuccess,
  consultoriosList,
}: PesquisaAvancadaProps) => {
  const [prevProfissionais, setPrevProfissionais] = useState<any[]>([]);
  const [profissionaisOptions, setProfissionaisOptions] = useState<any[]>([
    { nome: 'Todos', id: idTodos },
  ]);
  const [prevConvenios, setPrevConvenios] = useState<any[]>([]);
  const [conveniosListAPI, setConveniosListAPI] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>({});
  const [optionsCentroCusto, setOptionsCentroCusto] = useState<any[]>([]);
  const [tipoContaOptions, setTipoContaOptions] = useState<any[]>([]);
  const [optionsServicos, setOptionsServicos] = useState<any[]>([]);

  const { user, consultorios } = useAppSelector((state: RootState) => state);
  const { dropDownItemTemplate } = useSelectedContaReceber();
  const { setFilter } = useSearchList();
  const form = useFormContext();
  const { control, handleSubmit, watch, setValue, getValues } = form;

  const watchConsultorios = watch('idConsultorio');
  const watchProfissional = watch('idsProfissional');
  const watchConvenio = watch('convenios');
  const isMedico = user?.tipoProfissionalSaude === 'MEDICO';

  const fetchCentroCusto = useCallback(async () => {
    if (!watchConsultorios) {
      return setOptionsCentroCusto([]);
    }
    try {
      const response = await CentroCustoAPI.getCentroCusto({
        ...(watchConsultorios?.length > 0 &&
          !watchConsultorios.includes('todos') && {
            consultoriosIds: watchConsultorios.join(','),
          }),
      });
      setOptionsCentroCusto(response.list);
    } catch (error) {
      console.error(error);
      setOptionsCentroCusto([]);
    }
  }, [watchConsultorios]);

  const fetchTipoConta = useCallback(async () => {
    if (!watchConsultorios) {
      return setTipoContaOptions([]);
    }
    try {
      const response = await TipoContaReceberAPI.getTipoContaReceber({
        ...(watchConsultorios.length > 0 &&
          !watchConsultorios.includes('todos') && {
            idsConsultorios: watchConsultorios.join(','),
          }),
      });
      setTipoContaOptions(response.list);
    } catch (error) {
      console.error(error);
      setTipoContaOptions([]);
    }
  }, [watchConsultorios]);

  const fetchUserInfo = useCallback(async () => {
    setLoading(true);
    try {
      const u = await UsuarioAPI.loadUsuarioById(user.idUsuario);
      setUserInfo(u);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [user.idUsuario]);

  const fetchProfissionais = useCallback(async () => {
    setLoading(true);
    try {
      const response = await PesquisaPacienteAPI.loadProfissionaisConsultorio(
        consultorios?.ativo?.id,
      );
      if (Array.isArray(response?.data)) {
        setProfissionaisOptions(prevData => [...prevData, ...response?.data]);
        setValue('idsProfissional', [idTodos]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [consultorios?.ativo?.id, setValue]);

  const fetchServicos = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ServicoTussValorAPI.loadServicoTussValor({
        idEmpresa: user.idEmpresa,
        somenteVigentes: true,
      });
      setOptionsServicos(response.list);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [user.idEmpresa]);

  const onSubmit = () => {
    const v = getValues();
    const {
      dataVencimentoInicio,
      dataVencimentoFim,
      idsConsultorios,
      ...rest
    } = v;
    const params = {
      ...rest,
      dataVencimentoInicio: dataVencimentoInicio
        ? dayjs(dataVencimentoInicio).format('YYYY-MM-DD')
        : undefined,
      dataVencimentoFim: dataVencimentoFim
        ? dayjs(dataVencimentoFim).format('YYYY-MM-DD')
        : undefined,
      idsConsultorios: idsConsultorios?.includes('todos')
        ? consultoriosList?.map((c: any) => c.id).join(',')
        : idsConsultorios?.join(','),
    };
    setFilter(params);
    onSuccess?.();
  };

  useEffect(() => {
    fetchCentroCusto();
  }, [fetchCentroCusto]);

  useEffect(() => {
    fetchTipoConta();
  }, [fetchTipoConta]);

  useEffect(() => {
    if (isMedico) {
      fetchUserInfo();
    }
  }, [isMedico, fetchUserInfo]);

  useEffect(() => {
    if (isMedico) {
      setProfissionaisOptions([userInfo]);
      setValue('idsProfissional', [userInfo?.id]);
    } else if (consultorios?.ativo?.id) {
      fetchProfissionais();
    }
  }, [
    consultorios?.ativo?.id,
    isMedico,
    setValue,
    userInfo,
    fetchProfissionais,
  ]);

  useEffect(() => {
    setPrevProfissionais(watchProfissional);
    if (
      !prevProfissionais?.includes(idTodos) &&
      prevProfissionais?.length > 0 &&
      watchProfissional?.includes(idTodos)
    ) {
      setValue('idsProfissional', [idTodos]);
    }
    if (prevProfissionais?.includes(idTodos) && watchProfissional?.length > 1) {
      const filterTodos = watchProfissional?.filter(
        (id: number) => id !== idTodos,
      );
      setValue('idsProfissional', filterTodos);
    }
  }, [watchProfissional, prevProfissionais, setValue]);

  useEffect(() => {
    setPrevConvenios(watchConvenio);
    if (
      !prevConvenios?.includes(-1) &&
      prevConvenios?.length > 0 &&
      watchConvenio?.includes(-1)
    ) {
      setValue('convenios', [-1]);
    }
    if (prevConvenios?.includes(-1) && watchConvenio?.length > 1) {
      const filterTodos = watchConvenio.filter((id: any) => id !== -1);
      setValue('convenios', filterTodos);
    }
  }, [watchConvenio, prevConvenios, setValue]);

  useEffect(() => {
    fetchServicos();
  }, [fetchServicos]);

  return (
    <div
      className="p-p-1"
      style={{
        minWidth: '350px',
        maxWidth: '500px',
        borderRadius: '18px',
      }}
    >
      <div className="p-grid">
        <SimpleText fontColor="color_40" className="p-my-2">
          Pesquise de forma detalhada, preenchendo um ou mais dos campos abaixo.
        </SimpleText>

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <CalendarInputControlled
            className="p-w-100"
            control={control}
            name="dataVencimentoInicio"
            label="Data inicial"
            showIcon
          />
          <CalendarInputControlled
            className="p-w-100"
            control={control}
            name="dataVencimentoFim"
            label="Data final"
            showIcon
          />
        </div>

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <DropdownControlled
            className="p-w-100"
            control={control}
            name="credor"
            label="Credor"
            options={[
              { label: 'Consultório', value: 'CONSULTORIO' },
              { label: 'Profissional', value: 'PROFISSIONAL' },
            ]}
          />
        </div>

        <ConsultorioMultiSelect
          className="p-w-100 p-px-0 p-my-1"
          name="idsConsultorios"
        />

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <DropdownControlled
            control={control}
            name="idsCentroCusto"
            label="Centro de custo"
            options={optionsCentroCusto || []}
            optionLabel="nome"
            optionValue="id"
            className="p-w-100"
            disabled={!watchConsultorios?.length || !optionsCentroCusto}
            itemTemplate={dropDownItemTemplate}
          />
        </div>

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <DropdownControlled
            control={control}
            name="idsTipoContaReceber"
            label="Tipo de conta a receber"
            options={tipoContaOptions || []}
            optionLabel="tipo"
            optionValue="id"
            className="p-w-100"
            disabled={!watchConsultorios?.length || !tipoContaOptions}
            itemTemplate={dropDownItemTemplate}
          />
        </div>

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <MultipleCheckboxControlled
            className="p-w-100 p-ml-0"
            name="status"
            label="Status"
            options={optionsStatus}
            classNameContainer="p-d-flex p-gap-2 p-ml-0"
          />
        </div>

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <FieldProfissionais
            name="idsProfissional"
            label="Profissionais"
            className="p-w-100"
            options={profissionaisOptions}
            loading={loading}
            isMedico={isMedico}
          />
        </div>
        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <div className="p-d-flex p-gap-2 p-w-100">
            <FieldConvenio
              name="idsConvenio"
              label="Convênios"
              profissionais={profissionaisOptions}
              conveniosListAPI={conveniosListAPI}
              setConveniosListAPI={setConveniosListAPI}
              className="p-w-100"
            />
            <MultiSelectControlled
              control={control}
              name="idsServicoTuss"
              label="Código do procedimento"
              options={optionsServicos || []}
              optionLabel="nome"
              optionValue="codigo"
              className="p-w-100"
              placeholder={!optionsServicos?.length ? 'Nenhum encontrado' : ''}
              disabled={!optionsServicos?.length}
            />
          </div>
        </div>
        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <FieldSearchPaciente className="p-w-100" name="idsPaciente" idValue />
        </div>

        <div className="p-d-flex p-gap-2 p-w-100">
          <Button
            label="Cancelar"
            btnType="outline"
            stretch
            onClick={onSuccess}
          />
          <Button
            type="button"
            label="Pesquisar"
            onClick={() => handleSubmit(onSubmit)()}
            stretch
          />
        </div>
      </div>
    </div>
  );
};
