import { useRef } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch } from 'react-redux';

import dayjs from 'dayjs';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

import EscolhaNovoHorario from '../RemarcarAgendamento/EscolhaNovoHorario/EscolhaNovoHorario';

import { useBarraLateralMenuAgenda } from './BarraLateralMenuAgendaContext';

export const BarraLateralMenuAgendaReagendar = ({
  close,
}: {
  close(): void;
}) => {
  const dispatch = useDispatch();
  const {
    reagendamentos: items,
    isLoadingReagendamentos: isLoading,
    setIsLoadingReagendamentos,
  } = useBarraLateralMenuAgenda();

  const handleDeleteAgendamento = async (item: AtendimentoAgendamento) => {
    try {
      setIsLoadingReagendamentos(true);
      await AgendamentosAPI.editarAgendamento(
        {
          ...item,
          status: 'CANCELADO',
        },
        { throwError: true },
      );

      dispatch(
        setInvalidateQuery({
          invalidateReagendamentos: true,
        }),
      );
    } catch {
    } finally {
      setIsLoadingReagendamentos(false);
    }
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-p-1">
      <Button
        label="Voltar"
        btnType="ghost"
        onClick={close}
        className="p-col-3"
        icon="fas fa-arrow-left"
      />

      <SimpleText fontSize="md" className="p-pb-2" medium>
        Reagendamentos
      </SimpleText>

      {isLoading ? (
        <LoadingComponent />
      ) : (
        !!items.length && (
          <Accordion
            className="p-d-flex p-flex-column"
            style={{
              height: '560px',
              overflowY: 'auto',
            }}
          >
            {items.length &&
              items.map((item, index) => (
                <AccordionTab
                  header={<Header item={item} />}
                  className="p-flex-wrap"
                  key={index}
                  style={{
                    borderTopWidth: 0,
                  }}
                >
                  <AccContent
                    item={item}
                    onDelete={() => handleDeleteAgendamento(item)}
                  />
                </AccordionTab>
              ))}
          </Accordion>
        )
      )}

      {!isLoading && !items.length && (
        <div
          className="row content-row p-jc-center p-ai-center"
          style={{
            height: '110px',
          }}
        >
          <SimpleText fontColor="color_40" className="p-text-center" medium>
            Nenhum reagendamento encontrado para a agenda.
          </SimpleText>
        </div>
      )}
    </div>
  );
};

const Header = ({ item }: { item: AtendimentoAgendamento }) => {
  const {
    agenda: { profissionalAtivo },
  } = useAppSelector(state => state);
  return (
    <>
      <div className="p-col-12 p-d-flex p-gap-2 p-ai-center">
        <i className="fas fa-calendar icon-badge" />
        <SimpleText className="p-line-height-2" fontColor="color_40" medium>
          {dayjs(item.dataAgendamento).format(
            'dddd, D [de] MMM. [de] YYYY, [às] HH:mm',
          )}
        </SimpleText>
      </div>

      <SimpleText className="p-col-12" fontColor="color_40" fontSize="xxs">
        {profissionalAtivo.nome}{' '}
        {item.descricaoEspecialidade ? `· ${item.descricaoEspecialidade}` : ''}
      </SimpleText>
    </>
  );
};

const AccContent = ({
  item,
  onDelete,
}: {
  item: AtendimentoAgendamento;
  onDelete(): void;
}) => {
  const ref = useRef<OverlayPanel>(null);
  const dialogNovoHorarioAgendamento = useDisclosure({ opened: false });

  const isParticular = item.particular;

  const pacienteTelefone =
    item.agendamentoRapidoTelefone ||
    item.paciente.celularParticular ||
    item.paciente.celularComercial ||
    item.paciente.telefonePrincipal;

  return (
    <>
      <div className="row content-row p-flex-wrap p-col-12 p-gap-3">
        <div className="p-w-100 p-d-flex p-gap-2 p-ai-center">
          <i className="fas fa-user icon-badge" />
          <div className="p-d-flex p-flex-column p-gap-1">
            <SimpleText fontColor="color_40" medium>
              {item.paciente.nome}
            </SimpleText>
            <SimpleText fontColor="color_40">
              {isParticular ? 'Particular' : item?.nomeConvenio}
            </SimpleText>
          </div>
        </div>

        <div className="p-w-100 p-d-flex p-flex-column p-gap-2">
          <SimpleText
            fontColor="color_40"
            className="p-w-100 p-pb-1"
            fontSize="xxs"
            medium
          >
            Informações de contato
          </SimpleText>
          <SimpleText fontColor="color_40" fontSize="xxs">
            Fone:{' '}
          </SimpleText>
          <SimpleText fontSize="xs">{pacienteTelefone || ''}</SimpleText>
          <SimpleText fontColor="color_40" fontSize="xxs">
            E-mail:{' '}
          </SimpleText>
          <SimpleText fontSize="xs">{item.paciente.email || ''}</SimpleText>
        </div>
      </div>

      <div className="p-d-flex p-gap-2 p-mt-2">
        <Button
          label="Reagendar"
          onClick={() => dialogNovoHorarioAgendamento.open()}
        />
        <Button
          label="Excluir"
          btnType="ghost"
          onClick={e => ref?.current?.toggle(e)}
        />

        <OverlayPanel
          ref={ref}
          id="Delete-overlay"
          style={{
            padding: 0,
            width: '240px',
          }}
        >
          <div className="p-d-flex p-flex-wrap p-col-12">
            <SimpleText
              className="p-col-12 p-wordbreak-normal"
              fontColor="color_16"
              style={{
                lineHeight: '16px',
              }}
            >
              Você realmente deseja excluir o reagendamento. Essa ação não pode
              ser desfeita.
            </SimpleText>
            <Button
              label="Cancelar"
              btnType="ghost"
              className="p-col-6"
              onClick={() => ref.current?.hide()}
            />
            <Button
              label="Sim, excluir"
              btnType="danger"
              className="p-col-6"
              onClick={() => [ref.current?.hide(), onDelete()]}
            />
          </div>
        </OverlayPanel>

        {dialogNovoHorarioAgendamento.isOpen && (
          <EscolhaNovoHorario
            visible
            tipo="editar"
            editAgendamento={item}
            onHide={dialogNovoHorarioAgendamento.close}
          />
        )}
      </div>
    </>
  );
};

const LoadingComponent = () => (
  <div className="p-d-flex p-flex-column p-gap-1">
    <Skeleton
      className=""
      width="100%"
      height="77px"
      borderRadius="5px"
      loading
    />
    <Skeleton
      className=""
      width="100%"
      height="77px"
      borderRadius="5px"
      loading
    />
    <Skeleton
      className=""
      width="100%"
      height="77px"
      borderRadius="5px"
      loading
    />
  </div>
);
