import { useMemo } from 'react';

import { useNavigate } from 'react-router';

import { ContasReceberAPI } from 'src/APIs/FinanceiroAPI/ContasReceberAPI/ContasReceberAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { checkAuth } from 'src/utils/utils';

import { useSelectedContaReceber } from '../../useSelectedContaReceber';

import DesktopRow from './DesktopRow';
import MobileRow from './MobileRow';

interface Row {
  item: IContaReceberItem;
}

const Row = ({ item }: Row) => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const { reloadList } = useSelectedContaReceber();

  const navigate = useNavigate();

  const { windowInnerWidth } = useSize();

  const renderMobile = windowInnerWidth <= 1024;

  const onEdit = () => {
    navigate(`/faturamento/contas-receber/${item.id}`);
  };

  const onDelete = async () => {
    try {
      await ContasReceberAPI.deleteContaReceber(item.id);
      reloadList();
    } catch (error) {
      console.error(error);
    }
  };

  const actionItems = [
    {
      label: 'Editar',
      command: onEdit,
      checkPermission: 'ADM_CONTAS_RECEBER_ALTERAR',
    },
    {
      label: 'Excluir',
      command: onDelete,
      checkPermission: 'ADM_CONTAS_RECEBER_EXCLUIR',
      className: 'error',
    },
  ];

  const authorizedActions = actionItems.filter(item => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  const chipStatus =
    item.status === 'RECEBIDO'
      ? 'Recebido'
      : item.status === 'A_RECEBER'
      ? 'A Receber'
      : 'Parc. Recebido';

  const commonProps = {
    item,
    authorizedActions,
    chipStatus,
  };

  if (renderMobile) {
    return <MobileRow {...commonProps} />;
  }

  return <DesktopRow {...commonProps} />;
};

export default Row;
