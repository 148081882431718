import { useContext, useEffect, useMemo } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useAppDispatch } from 'src/core/redux/hooks';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import ThemeContext from 'src/core/themes/ThemeContext';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Tab from 'src/components/Tab/Tab';

import CentroCusto from './CentroCusto/CentroCusto';
import ContasReceber from './ContasReceber/ContasReceber';
import { SelectedContaReceberProvider } from './ContasReceber/useSelectedContaReceber';
import Fornecedor from './Fornecedor/Fornecedor';
import RelatoriosFaturamento from './RelatoriosFaturamento/RelatoriosFaturamento';
import { SelectedFaturamentosProvider } from './RelatoriosFaturamento/useSelectedFaturamentos';
import TipoContaPagar from './TipoContaPagar/TipoContaPagar';
import TipoContaReceber from './TipoContaReceber/TipoContaReceber';
import ValoresServico from './ValoresServico/ValoresServico';
import './Faturamento.scss';
import ContasPagar from './ContasPagar/ContasPagar';
import { SelectedContaPagarProvider } from './ContasPagar/useSelectedContaPagar';

const faturamentoOptions = [
  {
    label: 'Cadastrar fornecedor',
    path: '/faturamento/fornecedor',
    content: <Fornecedor />,
    permission: 'cadastrar_fornecedor',
    hide: true,
  },
  {
    label: 'Valor de serviços',
    path: '/faturamento/valores-servico',
    content: <ValoresServico />,
    permission: 'ADM_SERVICOS_FATURAMENTO_CONSULTAR',
  },
  {
    label: 'Relatório de faturamento',
    path: '/faturamento/relatorio',
    content: (
      <SelectedFaturamentosProvider>
        <RelatoriosFaturamento />
      </SelectedFaturamentosProvider>
    ),
    permission: 'ADM_REL_FATURAMENTO',
  },
  {
    label: 'Centro de custo',
    path: '/faturamento/centro-custo',
    content: <CentroCusto />,
    permission: 'ADM_MANTER_CENTRO_CUSTO_CONSULTAR',
  },
  {
    label: 'Tipo de conta a pagar',
    path: '/faturamento/tipo-conta-pagar',
    content: <TipoContaPagar />,
    permission: 'ADM_MANTER_TIPO_CONTA_PAGAR_CONSULTAR',
  },
  {
    label: 'Tipo de conta a receber',
    path: '/faturamento/tipo-conta-receber',
    content: <TipoContaReceber />,
    permission: 'ADM_MANTER_TIPO_CONTA_RECEBER_CONSULTAR',
  },
  {
    label: 'Contas a receber',
    path: '/faturamento/contas-receber',
    content: (
      <SelectedContaReceberProvider>
        <ContasReceber />
      </SelectedContaReceberProvider>
    ),
    permission: 'ADM_CONTAS_RECEBER_CONSULTAR',
  },
  {
    label: 'Contas a pagar',
    path: '/faturamento/contas-pagar',
    content: (
      <SelectedContaPagarProvider>
        <ContasPagar />
      </SelectedContaPagarProvider>
    ),
    permission: 'ADM_CONTAS_PAGAR_CONSULTAR',
  },
];

const Faturamento = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const dispatch = useAppDispatch();

  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(UsuarioAPI.loadUtils());
  }, [dispatch]);

  const tabs = useMemo((): any[] => {
    return faturamentoOptions.filter(({ permission }) =>
      authorities?.includes(permission),
    );
  }, [authorities]);

  //funcao temporaria para redirecionar para a primeira aba com permissao e sem disabled
  const getFirstEnabledTabPath = (tabs: any[]) => {
    const firstEnabledTab = tabs.find(tab => !tab.disabled);
    return firstEnabledTab?.path;
  };

  const firstEnabledTabPath = getFirstEnabledTabPath(tabs);

  useEffect(() => {
    if (location.pathname === '/faturamento') {
      navigate(firstEnabledTabPath || '/');
    }
  }, [firstEnabledTabPath, location.pathname, navigate]);

  const initialTabIndex = useMemo(
    () => tabs.findIndex(tab => tab.path === location.pathname),
    [location.pathname, tabs],
  );

  return (
    <Page className={`Financeiro ${theme?.theme || ''}`}>
      <>
        <PageHeader
          title={'Módulo Financeiro'}
          subtitle={'Gerencie os faturamento do sistema eMed'}
        />
        <Page className="page-content " content white>
          <Tab
            clean
            values={tabs}
            setActiveIndex={initialTabIndex}
            initialTabIndex={initialTabIndex}
            mode="path"
          />
        </Page>
      </>
    </Page>
  );
};

export default Faturamento;
