import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import CentroCustoAPI from 'src/APIs/FinanceiroAPI/CentroCustoAPI/CentroCustoAPI';

import ConsultorioMultiSelect from '../../components/ConsultorioMultiSelect';
import { PesquisaAvancada } from './Rows/components/PesquisaAvancada';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import { useSelectedContaPagar } from '../useSelectedContaPagar';
import validationSchema from '../validationSchema';

const currentDate = new Date();
const futureDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);

type FormData = {
  dataVencimentoInicio: Date;
  dataVencimentoFim: Date;
  idConsultorio: string[];
  idsCentroCusto: string[];
};

const Filter = () => {
  const [optionsCentroCusto, setOptionsCentroCusto] = useState<any[]>([]);
  const [consultoriosList, setConsultoriosList] = useState<any[]>([]);
  const { setFilter } = useSearchList();

  const { setSelectedContaPagar } = useSelectedContaPagar();

  const useFormMethods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dataVencimentoInicio: currentDate,
      dataVencimentoFim: futureDate,
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const watchDataInicial = watch('dataVencimentoInicio');
  const watchDataFinal = watch('dataVencimentoFim');
  const watchConsultorios = watch('idConsultorio');

  const onSubmit = async (data: any) => {
    setSelectedContaPagar([]);
    const {
      dataVencimentoInicio,
      dataVencimentoFim,
      idsConsultorios,
      ...rest
    } = data;
    const params = {
      ...rest,
      dataVencimentoInicio: dataVencimentoInicio
        ? dayjs(dataVencimentoInicio).format('YYYY-MM-DD')
        : undefined,
      dataVencimentoFim: dataVencimentoFim
        ? dayjs(dataVencimentoFim).format('YYYY-MM-DD')
        : undefined,
      idsConsultorios: idsConsultorios?.includes('todos')
        ? consultoriosList.map((c: any) => c.id).join(',')
        : idsConsultorios?.join(','),
    };
    setFilter(params);
  };

  useEffect(() => {
    if (!watchConsultorios) {
      return setOptionsCentroCusto([]);
    }
    const getCentroCusto = async () => {
      try {
        const response = await CentroCustoAPI.getCentroCusto({
          ...(watchConsultorios?.length > 0 &&
            !watchConsultorios.includes('todos') && {
              consultoriosIds: watchConsultorios.join(','),
            }),
        });

        setOptionsCentroCusto(response.list);
      } catch (error) {
        console.error(error);
        return setOptionsCentroCusto([]);
      }
    };
    getCentroCusto();
  }, [watchConsultorios]);

  return (
    <FormProvider {...useFormMethods}>
      <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
        <div className="pesquisa p-grid p-col-12">
          <ConsultorioMultiSelect
            className="p-col-12 p-md-6 p-lg-3 p-d-flex p-flex-column p-gap-1 p-mb-1"
            name="idsConsultorios"
            setConsultoriosList={setConsultoriosList}
          />

          <DropdownControlled
            control={control}
            name="idsCentroCusto"
            label="Centro de custo"
            options={optionsCentroCusto || []}
            optionLabel="nome"
            optionValue="id"
            className="p-col-12 p-md-6 p-lg-3"
            disabled={!watchConsultorios?.length || !optionsCentroCusto}
          />

          <div className="p-col-12 p-md-6 p-lg-2">
            <CalendarInputControlled
              control={control}
              name="dataVencimentoInicio"
              label="Data inicial"
              errorMsg={errors?.dataVencimentoInicio?.message}
              maxDate={watchDataFinal}
              showIcon
            />
          </div>

          <div className="p-col-12 p-md-6 p-lg-2">
            <CalendarInputControlled
              control={control}
              name="dataVencimentoFim"
              label="Data final"
              errorMsg={errors?.dataVencimentoFim?.message}
              maxDate={new Date()}
              minDate={watchDataInicial}
              showIcon
            />
          </div>

          <div className="p-col-4 p-md-2 p-d-flex p-ai-end">
            <Button
              className="p-mb-1"
              type="submit"
              label="Filtrar"
              btnType="tonal"
              loading={isSubmitting}
              stretch
            />
          </div>
          <div className="p-col-8 p-md-4 p-lg-12 p-d-flex p-ai-center p-jc-end">
            <Button
              className="p-mb-1 p-lg-2"
              label="Pesquisa avançada"
              btnType="tonal"
              icon="fas fa-chevron-down"
              iconPos="right"
              overlay={e => (
                <PesquisaAvancada
                  onSuccess={() => e?.hide()}
                  consultoriosList={consultoriosList}
                />
              )}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default Filter;
