import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { TipoContaReceberAPI } from 'src/APIs/FinanceiroAPI/TipoContaReceberAPI/TipoContaReceberAPI';

import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';

import TioContaReceberCreateForm from './TipoContaReceberCreateForm';

const ManterTipoContaReceber = () => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Page className={''}>
      <>
        <PageHeader
          title={
            params.id
              ? 'Editar tipo de conta a receber'
              : 'Novo tipo de conta a receber'
          }
          subtitle={'Preencha os campo para completar o cadastro'}
        />
        <Page content white className="p-pt-2" style={{ height: '100%' }}>
          <>
            <Button
              className="btn-voltar p-mb-2 p-ml-2"
              btnType="ghost"
              id="back-button"
              label="Voltar"
              icon="fa fa-arrow-left"
              onClick={() => navigate('/faturamento/tipo-conta-receber')}
            />
            <TioContaReceberCreateForm />
          </>
        </Page>
      </>
    </Page>
  );
};

export default ManterTipoContaReceber;
