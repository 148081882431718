import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { TipoContaReceberAPI } from 'src/APIs/FinanceiroAPI/TipoContaReceberAPI/TipoContaReceberAPI';

import ConsultorioDropdown from '../../components/ConsultorioDropdown';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Separator from 'src/components/Separator/Separator';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import './TipoContaReceberCreateForm.scss';

const TioContaReceberCreateForm = () => {
  const [loading, setLoading] = useState(true);
  const [editTipoContaReceber, setEditTipoContaReceber] =
    useState<IResponseTipoConta>();
  const params = useParams();
  const navigate = useNavigate();

  const useFormMethods = useForm();

  const {
    control,
    formState: { isSubmitting, isDirty },
    handleSubmit,
    reset,
  } = useFormMethods;

  const loadPage = useCallback(async () => {
    setLoading(true);
    try {
      const response = await TipoContaReceberAPI.getTipoContaReceberById(
        params.id,
      );
      setEditTipoContaReceber(response);
      reset({
        idConsultorio: response.consultorio?.id,
        ...response,
      });
    } catch (error) {
      console.error(error);
      navigate('/faturamento/tipo-conta-receber');
    } finally {
      setLoading(false);
    }
  }, [navigate, params.id, reset]);

  useEffect(() => {
    if (params.id) {
      loadPage();
    } else {
      setLoading(false);
    }
  }, [loadPage, params.id]);

  const onSubmit = async (data: any) => {
    try {
      if (editTipoContaReceber) {
        await TipoContaReceberAPI.putTipoContaReceber(editTipoContaReceber.id, {
          ...data,
          id: editTipoContaReceber.id,
          status: data.status || false,
        });
      } else {
        await TipoContaReceberAPI.postTipoContaReceber(data);
      }
      navigate('/faturamento/tipo-conta-receber');
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <GridListLoading />;

  return (
    <FormProvider {...useFormMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="form-tipo-conta-receber"
        className="p-d-grid p-flex-column p-col-12 p-md-8 p-lg-6 p-xl-5"
      >
        <ConsultorioDropdown
          className="p-col-12"
          idConsultorioEdit={editTipoContaReceber?.idConsultorio}
        />
        <FormInput
          name="tipo"
          label="Tipo de conta a receber"
          hideTextErrorSpace
          className="p-col-12"
          maxLength={250}
        />
        <FormInput
          name="descricao"
          label="Descrição"
          hideTextErrorSpace
          className="p-col-12"
          maxLength={250}
        />
        <div className="p-col-12">
          <Separator layout="horizontal" />
        </div>
        <div className="p-d-flex p-jc-between p-ai-center p-p-2 border p-col-12">
          <SimpleText medium>Status</SimpleText>
          <SwitchControlled control={control} name="status" showStatus />
        </div>
        <Button
          type="submit"
          label="Salvar"
          disabled={!isDirty}
          loading={isSubmitting}
          className="p-col-12 p-mt-3"
        />
      </form>
    </FormProvider>
  );
};

export default TioContaReceberCreateForm;
