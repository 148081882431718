import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import { AvaliacaoFormularioDinamicoAPI } from 'src/APIs/ProntuarioAPI/AvaliacaoFormularioDinamicoAPI/AvaliacaoFormularioDinamicoAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { cn } from 'src/utils/cn';

import { DialogTabChange } from './components/DialogTabChange';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Card from 'src/components/Basics/Card/Card';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';
import {
  TabVariant,
  TabVariantItems,
} from 'src/components/TabVariant/TabVariant';

import BarraLateralProntuario from '../Atendimento/IniciarAtendimento/BarraLateralProntuario/BarraLateralProntuario';
import { Telemedicina } from '../Atendimento/Telemedicina/Telemedicina';

import { FormulariosContent } from './FormulariosContent';
import { FormularioHistorico } from './FormulariosHistorico';
import { FormularioProvider, useFormulario } from './useFormulariosContext';

import './Formularios.scss';

export const Formularios = ({ idPaciente }: { idPaciente: number | null }) => {
  const { showBarraLateral, isTeleatendimento, atendimentoStatus } =
    useAtendimento();

  const classNameContent =
    showBarraLateral || isTeleatendimento ? 'p-md-9' : 'p-md-11';

  const isAtendimentoRunning = ['ATENDENDO', 'CONTINUAR'].includes(
    atendimentoStatus,
  );

  return (
    <div className="p-d-flex p-flex-column p-flex-md-row p-flex-wrap">
      <div
        className={cn(
          'p-mt-2 p-px-2 p-pt-2 p-sm-12 p-d-flex p-flex-column p-gap-2',
          classNameContent,
        )}
      >
        <AlertBox
          visible={!isAtendimentoRunning}
          text="Inicie o atendimento para ver os formulários disponíveis para o paciente."
        />
        <FormularioProvider isAtendimentoRunning={isAtendimentoRunning}>
          <FormulariosComponent />
          <FormularioHistorico />
        </FormularioProvider>
      </div>

      <div
        id="barra-lateral-prontuario"
        className={showBarraLateral || isTeleatendimento ? 'p-sm-3' : 'p-sm-1'}
      >
        {isTeleatendimento && <Telemedicina />}
        {idPaciente && <BarraLateralProntuario idPaciente={idPaciente} />}
      </div>
    </div>
  );
};

const FormulariosComponent = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { ativo } = useAppSelector(state => state.consultorios);
  const { query } = useAppSelector(state => state);
  const { isFormularioDirty, setIsFormularioDirty } = useAtendimento();
  const {
    prontuario,
    aplicacaoformularios,
    setAplicacaoFormularios,
    selectedAplicacaoFormulario,
    isLoadingFormulario,
    setSelectedAplicacaoFormulario,
    avaliacaoEdit,
    handleChangeForm,
  } = useFormulario();

  const dialogTabChange = useDisclosure({ opened: false });
  const dispatch = useDispatch();

  const { invalidateFormulario } = query;

  const fetchFormularios = useCallback(async () => {
    if (!prontuario) return;

    try {
      setIsLoading(true);

      const response =
        await AvaliacaoFormularioDinamicoAPI.loadAvaliacoesFormularioDinamico(
          {
            idEspecialidade: prontuario.atendimento?.especialidade.id,
            idConsultorio: ativo.id,
            idConvenio: prontuario.atendimento?.idConvenio,
            idPaciente: prontuario.atendimento?.paciente.id,
          },
          { throwError: true },
        );

      !!response && setAplicacaoFormularios(response);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, [ativo.id, prontuario, setAplicacaoFormularios, setIsLoading]);

  const onChangeTab = (index: number) => {
    if (isFormularioDirty) {
      return dialogTabChange.open({
        state: () => [
          setTabIndex(index),
          handleChangeForm(),
          setIsFormularioDirty(false),
        ],
      });
    }
    return [setTabIndex(index), handleChangeForm()];
  };

  const isLoadingForm = isLoadingFormulario || isLoading;
  const isActiveForm = selectedAplicacaoFormulario?.situacao === 'ATIVO';
  const parecer = !!avaliacaoEdit && isActiveForm && avaliacaoEdit.pontuacao;
  const isParecerNull = parecer === null;

  const pBottom = useMemo(() => {
    if (!!parecer) return '330px';
    if (isParecerNull) return '205px';
    if (!!aplicacaoformularios.length) return '85px';
    return '2px';
  }, [aplicacaoformularios.length, isParecerNull, parecer]);

  const tabItems: TabVariantItems[] = useMemo(
    () =>
      aplicacaoformularios.map(aplicacao => ({
        title: aplicacao.formularioDinamico.nome,
        content: <FormulariosContent aplicacaoFormulario={aplicacao} />,
        bgColor: aplicacao.situacao !== 'ATIVO' ? 'dark-grey' : 'light-grey',
      })),
    [aplicacaoformularios],
  );

  useEffect(() => {
    fetchFormularios();
  }, [fetchFormularios]);

  useEffectSkipFirst(() => {
    if (invalidateFormulario) {
      fetchFormularios();
      dispatch(resetInvalidateQuery());
    }
  }, [fetchFormularios, invalidateFormulario, dispatch]);

  useEffectSkipFirst(() => {
    if (aplicacaoformularios.length) {
      setSelectedAplicacaoFormulario(aplicacaoformularios[tabIndex]!);
    }
  }, [tabIndex, aplicacaoformularios]);

  return (
    <Card
      style={{
        position: 'relative',
        paddingBottom: isLoadingForm || !isActiveForm ? '0' : pBottom,
      }}
    >
      <div className="p-d-flex p-flex-column p-gap-2 p-p-1">
        <SimpleText fontSize="md" medium>
          Formulários disponíveis para avaliação
        </SimpleText>

        {isLoadingForm && <LoadingComponenet />}
        {!isLoadingForm && tabItems.length > 0 && (
          <div className="row content-row-outline p-p-3 p-mt-3">
            <TabVariant
              activeIndex={tabIndex}
              onChange={onChangeTab}
              maxH="calc(100vh - 200px)"
              items={tabItems}
            />

            {parecer && (
              <div className="Pontuacao-form-container row content-row-outline primary p-p-2 p-my-3 p-jc-end p-flex-wrap">
                <div
                  id="Pontuacao-box"
                  className="row content-row-outline primary p-p-2 p-ai-center p-text-center"
                >
                  <SimpleText>
                    Pontuação: <strong>{avaliacaoEdit.totalPontuacao}</strong>
                  </SimpleText>
                </div>

                <div
                  id="Parecer-box-content"
                  className="p-col-12 row content-row p-flex-column p-gap-3 p-p-3"
                >
                  <SimpleText fontSize="md" medium>
                    Parecer
                  </SimpleText>
                  <SimpleText fontSize="sm" medium>
                    {parecer.nome}
                  </SimpleText>
                  <SimpleText lines={5}>{parecer.mensagemPaciente}</SimpleText>
                </div>
              </div>
            )}
          </div>
        )}

        {isParecerNull && (
          <div className="Pontuacao-empty row content-row-outline primary p-p-2">
            <div className="row content-row content-row-outline p-flex-column p-gap-2 p-p-2 p-w-100">
              <SimpleText fontSize="md" medium>
                Parecer
              </SimpleText>
              <SimpleText fontSize="sm" medium>
                Nenhum parecer foi gerado!
              </SimpleText>
              <SimpleText fontColor="color_40">
                Isso pode ser devido ao formulário conter apenas perguntas em
                texto ou à pontuação não ter um parecer associado a essa
                pontuação.
              </SimpleText>
            </div>
          </div>
        )}

        {!isLoadingForm && !aplicacaoformularios.length && (
          <div className="row content-row p-jc-center p-p-4 p-my-2">
            <SimpleText fontSize="sm" medium>
              Nenhum formulário encontrado para esse paciente.
            </SimpleText>
          </div>
        )}

        {dialogTabChange.isOpen && <DialogTabChange {...dialogTabChange} />}
      </div>
    </Card>
  );
};

const LoadingComponenet = () => {
  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-my-2">
      <div className="row content-row-outline  p-flex-column p-p-3 p-gap-2">
        <div className="p-d-flex p-flex p-gap-2 p-mb-1">
          <Skeleton loading borderRadius="8px" height="30px" width="200px" />
          <Skeleton loading borderRadius="8px" height="30px" width="60px" />
          <Skeleton loading borderRadius="8px" height="30px" width="120px" />
        </div>
        <Skeleton loading borderRadius="8px" height="120px" />
        <Skeleton loading borderRadius="8px" height="120px" />
      </div>

      <div className="p-mt-1 p-d-flex p-gap-2">
        <Skeleton loading borderRadius="8px" height="35px" />
        <Skeleton loading borderRadius="8px" height="35px" />
      </div>
    </div>
  );
};
