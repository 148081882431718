import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { cn } from 'src/utils/cn';
import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHeaderProps {
  item: IContaPagarItem;
  isCollapsed: boolean;
  onToggle: () => void;
  selectedContaPagar: any;
  setSelectedContaPagar: any;
  authorizedActions: any;
  chipStatus: string;
}

const PanelHeader = ({
  item,
  isCollapsed,
  onToggle,
  selectedContaPagar,
  setSelectedContaPagar,
  authorizedActions,
  chipStatus,
}: PanelHeaderProps) => {
  const { windowInnerWidth } = useSize();

  const ref = useRef<TieredMenu>(null);

  const desktopSize = windowInnerWidth > 1200;

  return (
    <div className="p-grid p-d-flex p-ai-center p-py-2 p-ai-stat p-jc-start">
      <div className="p-col p-d-flex">
        {!desktopSize ? (
          <>
            <Checkbox
              checked={selectedContaPagar?.includes(item)}
              onChange={e => {
                if (e.checked) {
                  setSelectedContaPagar(
                    selectedContaPagar.length > 0
                      ? [...selectedContaPagar, item]
                      : [item],
                  );
                } else {
                  setSelectedContaPagar(
                    selectedContaPagar?.filter((i: any) => i !== item),
                  );
                }
              }}
            />
            <div className="p-d-flex p-flex-column p-ml-1">
              <SimpleText fontSize="xxs" fontColor="color_60">
                Data de vencimento:
              </SimpleText>
              <SimpleText fontSize="xs" fontColor="color_16">
                {item?.dataVencimento
                  ? dayjs(new Date(item?.dataVencimento)).format('DD/MM/YYYY')
                  : 'Não informado'}
              </SimpleText>
            </div>
          </>
        ) : (
          <Checkbox
            checked={selectedContaPagar?.includes(item)}
            onChange={e => {
              if (e.checked) {
                setSelectedContaPagar(
                  selectedContaPagar.length > 0
                    ? [...selectedContaPagar, item]
                    : [item],
                );
              } else {
                setSelectedContaPagar(
                  selectedContaPagar?.filter((i: any) => i !== item),
                );
              }
            }}
            label={
              item?.dataVencimento
                ? dayjs(new Date(item?.dataVencimento)).format('DD/MM/YYYY')
                : 'Não informado'
            }
          />
        )}
      </div>
      <div className="p-col p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize="xxs" fontColor="color_60">
            Pagamento:
          </SimpleText>
        )}
        <SimpleText fontSize="xs" fontColor="color_16">
          {item?.dataPagamento
            ? dayjs(new Date(item?.dataPagamento)).format('DD/MM/YYYY')
            : ''}
        </SimpleText>
      </div>
      <div className="p-col p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize="xxs" fontColor="color_60">
            Fornecedor:
          </SimpleText>
        )}
        <SimpleText fontSize="xs" fontColor="color_16">
          {item?.fornecedor?.nome || ''}
        </SimpleText>
      </div>
      <div className="p-col p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize="xxs" fontColor="color_60">
            Consultório:
          </SimpleText>
        )}
        <SimpleText fontSize="xs" fontColor="color_16">
          {item?.consultorio.nome &&
            capitalizeFirstLetter(item?.consultorio.nome)}
        </SimpleText>
      </div>
      <div className="p-col p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize="xxs" fontColor="color_60">
            Centro de custo:
          </SimpleText>
        )}
        <SimpleText fontSize="xs" fontColor="color_16">
          {capitalizeFirstLetter(item?.centroCusto.nome)}
        </SimpleText>
      </div>
      <div className="p-col p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize="xxs" fontColor="color_60">
            Tipo:
          </SimpleText>
        )}
        <SimpleText fontSize="xs" fontColor="color_16">
          {item?.tipoContaPagar?.tipo || ''}
        </SimpleText>
      </div>
      <div className="p-col p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize="xxs" fontColor="color_60">
            Valor:
          </SimpleText>
        )}
        <SimpleText fontSize="xs" fontColor="color_16">
          {item?.valor?.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </SimpleText>
      </div>
      <div className="p-col p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize="xxs" fontColor="color_60">
            Valor pago:
          </SimpleText>
        )}
        <SimpleText fontSize="xs" fontColor="color_16">
          {(item?.valorPago || 0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </SimpleText>
      </div>

      <div
        className={cn(
          'p-col p-py-1 p-d-flex p-flex-column',
          chipStatus === 'Paga'
            ? 'chip-success-light'
            : chipStatus === 'A Pagar'
            ? 'chip-yellow'
            : 'chip-feedback-warning ',
        )}
      >
        <SimpleText medium fontSize="xxs" fontColor="color_16">
          {chipStatus}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-ai-center p-justify-end p-py-0 p-gap-1">
        <Button
          btnType="gray"
          icon="fas fa-ellipsis"
          className="p-col-6 p-ml-auto"
          onClick={e => ref.current?.toggle(e)}
          disabled={!authorizedActions?.length}
        />

        <TieredMenu ref={ref} popup model={authorizedActions} />
        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="gray"
          onClick={onToggle}
        />
      </div>
    </div>
  );
};

export default PanelHeader;
