import { createContext, useCallback, useContext, useState } from 'react';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const ContaReceberContext = createContext({} as any);

export function SelectedContaReceberProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedContaReceber, setSelectedContaReceber] = useState([]);
  const [reload, setReload] = useState(0);

  const reloadList = useCallback(() => {
    setReload(currentReload => currentReload + 1);
  }, []);

  const dropDownItemTemplate = useCallback((item: any) => {
    return (
      <SimpleText
        className="p-d-flex p-ai-center p-text-truncate"
        style={{ maxWidth: '10rem' }}
      >
        {item.tipo || item.nome}
      </SimpleText>
    );
  }, []);

  return (
    <ContaReceberContext.Provider
      value={{
        selectedContaReceber,
        setSelectedContaReceber,
        reload,
        setReload,
        reloadList,
        dropDownItemTemplate,
      }}
    >
      {children}
    </ContaReceberContext.Provider>
  );
}

export function useSelectedContaReceber() {
  const context = useContext(ContaReceberContext);

  return context;
}
