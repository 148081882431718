import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useSubscription } from 'react-stomp-hooks';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import useSize from 'src/core/hooks/useSize';
import { useWebSigner } from 'src/core/hooks/useWebSigner';
import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import {
  clearProfissionais,
  setProfissionaisDisponiveis,
  setProfissionalAtivo,
} from 'src/core/redux/slices/agenda/AgendaSlice';
import { onSairAtendimento } from 'src/core/redux/slices/atendimento/AtendimentoSlice';
import { clearUser } from 'src/core/redux/slices/system/UserSlice';
import {
  setConsultorioAtivo,
  setConsultorios,
} from 'src/core/redux/slices/user/ConsultoriosSlice';
import { RootState } from 'src/core/redux/store';
import { RouteItem, userRoutes } from 'src/core/routes/Emed/RouterMap';
import ThemeContext from 'src/core/themes/ThemeContext';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI';
import Avatar from 'src/components/Avatar/Avatar';
import CustomImage from 'src/components/Basics/CustomImage/CustomImage';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import Skeleton from 'src/components/Skeleton/Skeleton';

import DisponibilidadeMedica from 'src/pages/Emed/Agenda/MenuAgenda/DisponibilidadeMedico/DisponibilidadeMedica';
import ConfigSubMenu from 'src/pages/Emed/LoggedRoot/MainMenu/ConfigSubMenu/ConfigSubMenu';

import MainMenuNotification from './MainMenuNotification/MainMenuNotification';
import SelectConsultorio from './SelectEmpresaConsultorio/SelectConsultorio/SelectConsultorio';
import SelectEmpresa from './SelectEmpresaConsultorio/SelectEmpresa/SelectEmpresa';

import './MainMenu.scss';

const MainMenu = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useAppDispatch();

  const { isXXL, isMobile, isTablet } = useSize();
  const { pathname } = useLocation();
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { setWebSigner, hsmValidDate, modeloAssinatura } = useAssinatura();
  const { user, consultorios, atendimento } = useAppSelector(
    (state: RootState) => state,
  );
  const { theme } = useContext(ThemeContext);
  const webSigner = useWebSigner();

  const renderLogo = useCallback(() => {
    if (isMobile) {
      return (
        <CustomImage
          src={
            theme === 'light-theme'
              ? 'emed_logo_mini_light.svg'
              : 'emed_logo_mini_dark.svg'
          }
          width={'40%'}
          alt="LOGO_EMED"
        />
      );
    }

    return (
      <CustomImage
        src={
          theme === 'light-theme' ? 'emed_logo_light.svg' : 'emed_logo_dark.svg'
        }
        width={isXXL ? '160px' : '120px'}
        alt="LOGO_EMED"
      />
    );
  }, [isXXL, isMobile, theme]);

  const canSign =
    user?.tipoUsuario === 'PROFISSIONAL_SAUDE' && modeloAssinatura === 'HSM';

  const dialogDisponibilidade = useDisclosure({ opened: false });

  useEffectSkipFirst(() => {
    setWebSigner(webSigner);
  }, [webSigner.isLoaded]);

  useSubscription('/user/topic/sessoes', () => {
    dispatch(clearUser());
    dispatch(clearProfissionais());
  });

  const [userInfo, setUserInfo] = useState<any>();

  const loadSensitiveUtils = useCallback(async () => {
    if (user?.idUsuario) {
      const aux = await UsuarioAPI.loadSensitiveUtils(user);
      const u = await UsuarioAPI.loadUsuarioById(user.idUsuario);

      setUserInfo(u);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.idUsuario]);

  const loadMedico = useCallback(async () => {
    if (user?.idUsuario) {
      try {
        const aux = await UsuarioAPI.loadUsuarioById(user?.idUsuario || -1);

        if (aux && aux?.tipo === 'PROFISSIONAL_SAUDE') {
          dispatch(setProfissionalAtivo(aux));
        }
      } catch {}
    }
  }, [dispatch, user?.idUsuario]);

  const loadConsultorios = useCallback(async () => {
    try {
      const aux = await ConsultorioAPI.loadUserConsultorios();

      if (aux && Array.isArray(aux) && aux.length > 0) {
        dispatch(setConsultorios(aux));
      } else {
        dispatch(setConsultorios([]));
      }
    } catch {
      dispatch(setConsultorios([]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user]);

  useEffect(() => {
    loadSensitiveUtils();
    loadConsultorios();
  }, [loadConsultorios, loadSensitiveUtils]);

  useEffect(() => {
    loadMedico();
  }, [loadMedico]);

  useEffect(() => {
    const loadMedicosDisponiveis = async () => {
      const disponiveis = await UsuarioAPI.loadProfissionaisByUserConsultorio(
        consultorios?.ativo.id,
      );

      // const ativo = await UsuarioAPI.loadUsuarioById(user?.idUsuario || -1);

      // const profissionalSaudeAtivo = disponiveis.find(
      //   (profissionalSaude: GetUsuarioDTO) =>
      //     profissionalSaude.id === ativo?.id,
      // );

      // if (
      //   user?.tipoUsuario === 'PROFISSIONAL_SAUDE' &&
      //   user?.tipoProfissionalSaude !== 'ENFERMEIRO'
      // ) {
      //   dispatch(
      //     setProfissionalAtivo(profissionalSaudeAtivo || disponiveis[0]),
      //   );
      // }

      dispatch(setProfissionaisDisponiveis(disponiveis));
    };
    if (consultorios?.ativo?.id) {
      loadMedicosDisponiveis();
    }
  }, [
    consultorios?.ativo?.id,
    dispatch,
    user?.idUsuario,
    user?.tipoProfissionalSaude,
    user?.tipoUsuario,
  ]);

  /**
   * Every time a refresh token runs, it reload the list of consultorios this user has access to
   * If it has no active Consultorio, it select the first one available
   * If it already has a selected Consultorio, it checks if the user still has
   * ...access to the selected Consultorio, if not, it cleans redux memory
   * ...which will reselect the first one available
   */
  const setActiveConsultorio = useCallback(() => {
    //Set active Consultorio if none exists
    if (
      !consultorios.ativo &&
      consultorios.disponiveis &&
      Array.isArray(consultorios.disponiveis) &&
      !!consultorios.disponiveis.length
    ) {
      dispatch(setConsultorioAtivo(consultorios.disponiveis[0]));
    }
    //If there is an active Consultorio
    // Check if the user still has permission to use it
    if (consultorios.ativo) {
      const consultorioAtivo = consultorios.disponiveis.find(
        (c: GetConsultorioDTO) => c.id === consultorios.ativo.id,
      );

      dispatch(
        setConsultorioAtivo(consultorioAtivo ?? consultorios.disponiveis?.[0]),
      );
    }
  }, [consultorios, dispatch]);

  useEffect(() => {
    setActiveConsultorio();
  }, [setActiveConsultorio]);

  const canSelectConsultorio =
    user.tipoUsuario === 'PROFISSIONAL_SAUDE' ||
    user.tipoUsuario === 'SECRETARIO';

  const renderUserInfo = useCallback(() => {
    return (
      <>
        <div className="userInfo p-d-flex p-jc-end p-ai-center p-gap-2">
          {!isMobile && (
            <div className="display-info p-d-flex p-ai-end p-flex-column">
              <Skeleton loading={!userInfo}>
                <SimpleText
                  lines={1}
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XS}
                  style={{
                    maxWidth: isTablet ? '130px' : '100%',
                  }}
                  medium
                >
                  {userInfo?.nome || ''}
                </SimpleText>
              </Skeleton>

              {user.tipoUsuario === 'MASTER' && (
                <Button
                  className="p-p-0 p-m-0 consultorio-info"
                  icon="fas fa-caret-down"
                  btnType="green-link"
                  label={user.idEmpresa ? user.nomeEmpresa : 'Cliente'}
                  iconPos="right"
                  btnSize="small"
                  title={user.idEmpresa ? user.nomeEmpresa : 'Cliente'}
                  overlay={<SelectEmpresa />}
                />
              )}

              {user.tipoUsuario === 'ADMINISTRADOR' && (
                <div className="p-d-flex p-ai-center p-gap-1 empresa-info">
                  <SimpleText
                    lines={1}
                    fontColor={FONT_COLOR.COLOR_60}
                    fontSize={FONT_SIZE.XXXS}
                  >
                    {user.nomeEmpresa ? user.nomeEmpresa : 'Cliente'}
                  </SimpleText>
                  <i className="fa-regular fa-hospital" />
                </div>
              )}

              {canSelectConsultorio && (
                <>
                  <div className="p-d-flex p-ai-center p-gap-1 empresa-info">
                    <SimpleText
                      lines={1}
                      fontColor={FONT_COLOR.COLOR_60}
                      fontSize={FONT_SIZE.XXXS}
                    >
                      {user.nomeEmpresa ? user.nomeEmpresa : 'Cliente'}
                    </SimpleText>
                    <i className="fa-regular fa-hospital" />
                  </div>

                  <Button
                    className="p-p-0 p-m-0 consultorio-info"
                    icon="fas fa-caret-down"
                    btnType="green-link"
                    label={
                      consultorios.ativo
                        ? consultorios.ativo?.nome
                        : 'Consultório'
                    }
                    iconPos="right"
                    btnSize="small"
                    overlay={<SelectConsultorio />}
                  />
                </>
              )}
            </div>
          )}

          <div style={{ minWidth: '34px' }}>
            <Avatar
              image={userInfo?.fotoPerfil || null}
              label={userInfo?.nome}
            />
          </div>
        </div>

        {canSign && (
          <div className="content-assinatura-status p-ml-2">
            <SimpleText
              className={`senha-assinatura ${hsmValidDate ? 'sign-on' : ''}`}
              fontColor={
                hsmValidDate ? FONT_COLOR.PRIMARY : FONT_COLOR.COLOR_60
              }
              fontSize={FONT_SIZE.XXXS}
            >
              Senha de assinatura{' '}
            </SimpleText>
          </div>
        )}
      </>
    );
  }, [
    isMobile,
    userInfo,
    isTablet,
    user.tipoUsuario,
    user.idEmpresa,
    user.nomeEmpresa,
    canSelectConsultorio,
    consultorios.ativo,
    canSign,
    hsmValidDate,
  ]);

  const renderUserConfig = useCallback(() => {
    return (
      <div className={'userConfig'}>
        <MainMenuNotification />
        <Button
          icon={'pi pi-ellipsis-v'}
          btnType="gray"
          overlay={<ConfigSubMenu canSign={canSign} />}
        />
      </div>
    );
  }, [canSign]);

  const renderUserMenu = useCallback(() => {
    return (
      <div className={'userMenu'}>
        {renderUserInfo()}
        <Separator layout={'vertical'} />
        {renderUserConfig()}
      </div>
    );
  }, [renderUserInfo, renderUserConfig]);

  const isSameAtendimento = useMemo(
    () => Number(idAtendimento) === atendimento.id,
    [idAtendimento, atendimento.id],
  );

  const handleClick = useCallback(
    (route: string) => {
      if (isSameAtendimento) {
        if (
          (atendimento?.status === 'ATENDENDO' ||
            atendimento?.status === 'PRESCRICAO_EXECUCAO') &&
          pathname?.includes('prontuario')
        ) {
          dispatch(onSairAtendimento({ route }));
          return;
        }
      }
      return navigate(route);
    },
    [isSameAtendimento, navigate, atendimento?.status, pathname, dispatch],
  );

  const generateMenuItems = useCallback(
    (routes: RouteItem[]): MenuItem[] => {
      const result: MenuItem[] = [];

      const { authorities } = user;

      routes.forEach((r: RouteItem) => {
        const aux: MenuItem = {
          label: r.label,
        };
        if (Array.isArray(r?.checkPermission)) {
          const hasPermission = r?.checkPermission.some(permission =>
            authorities?.includes(permission),
          );

          if (!hasPermission) return;
        } else if (
          r?.checkPermission &&
          !authorities?.includes(r?.checkPermission)
        ) {
          return;
        }

        if (r.hideFromMenu) {
          return;
        } else if (r.items) {
          aux.items = generateMenuItems(r.items);
          // remove hidden items
          aux.items = aux.items.filter(item => item.label);
          if (aux.items.length === 0) {
            delete aux.items;
            return;
          }
        } else {
          const onClick = () => {
            handleClick(r.route);
          };
          aux.command = onClick;
        }

        result.push(aux);
      });

      if (authorities?.includes('AGE_DISPONIBILIDADE_MEDICA')) {
        const cadastroMenu: any = result.find(
          menu => menu.label === 'Cadastros',
        );

        cadastroMenu?.items?.push({
          label: 'Disponibilidade Médica',
          command: () => dialogDisponibilidade.open(),
        });
      }

      return result;
    },
    [dialogDisponibilidade, handleClick, user],
  );

  return (
    <div id="Main-menu">
      {isXXL ? (
        <Menubar
          className={'MainMenu p-d-flex '}
          start={renderLogo()}
          model={generateMenuItems(userRoutes)}
          end={renderUserMenu()}
        />
      ) : (
        <Menubar
          className={'MainMenu p-d-flex p-w-100'}
          start={
            <div className="p-d-flex p-jc-between p-ai-center p-md-mb-2">
              {renderLogo()}
              {renderUserMenu()}
            </div>
          }
          model={generateMenuItems(userRoutes)}
        />
      )}

      {dialogDisponibilidade.isOpen && (
        <DisponibilidadeMedica {...dialogDisponibilidade} />
      )}
    </div>
  );
};

export default MainMenu;
