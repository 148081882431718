import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { PesquisaPacienteAPI } from 'src/APIs/AgendaAPI/PesquisaPacienteAPI/PesquisaPacienteAPI';
import CentroCustoAPI from 'src/APIs/FinanceiroAPI/CentroCustoAPI/CentroCustoAPI';
import { ContasReceberAPI } from 'src/APIs/FinanceiroAPI/ContasReceberAPI/ContasReceberAPI';
import { TipoContaReceberAPI } from 'src/APIs/FinanceiroAPI/TipoContaReceberAPI/TipoContaReceberAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { convertCurrencyToNumber } from 'src/utils/utils';

import ConsultorioDropdown from '../../components/ConsultorioDropdown';
import FieldConvenio from '../../components/FieldConvenio';
import FieldProfissionais from '../../components/FieldProfissionais';
import ServicosTuss from '../../components/ServicosTuss';
import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { InputNumber } from 'src/components/Basics/Input';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import { FieldSearchPaciente } from 'src/components/Fields/FieldSearchPacientes/FieldSearchPacientes';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Separator from 'src/components/Separator/Separator';

import validationSchema from './validationSchema';

type FormData = {
  dataVencimento: Date;
  dataRecebimento: Date;
  idConsultorio: any;
  idCentroCusto: number;
  idTipoContaReceber: number;
  idProfissionalSaude: number;
  idConvenio: number;
  idPaciente: number;
  descricao: string;
  valor: number | string;
  valorRecebido: number | string;
  status: 'A_RECEBER' | 'RECEBIDO' | 'PARCIALMENTE_RECEBIDO';
  dataAtendimento: Date;
  servicos: any[];
};

const ContasReceberCreateForm = () => {
  const [loading, setLoading] = useState(true);
  const [editContaReceber, setEditContaReceber] = useState<any>();
  const [conveniosListAPI, setConveniosListAPI] = useState<any[]>([]);
  const [optionsCentroCusto, setOptionsCentroCusto] = useState<any[]>([]);
  const [tipoContaOptions, setTipoContaOptions] = useState<any[]>([]);
  const [profissionaisOptions, setProfissionaisOptions] = useState<any[]>([]);
  const [valor, setValor] = useState<number>(0);
  const [valorRecebido, setValorRecebido] = useState<number>(0);

  const { user, consultorios } = useAppSelector((state: RootState) => state);

  const params = useParams();
  const navigate = useNavigate();
  const isMedico = user?.tipoUsuario === 'PROFISSIONAL_SAUDE';

  const useFormMethods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      idProfissionalSaude: isMedico ? user?.idUsuario : undefined,
      idConsultorio: consultorios?.ativo?.id,
      valor: 0,
      valorRecebido: 0,
    },
  });

  const {
    control,
    watch,
    formState: { isSubmitting, isDirty, errors },
    handleSubmit,
    setValue,
    reset,
  } = useFormMethods;

  const watchConsultorios = watch('idConsultorio');
  const watchValor = watch('valor');
  const watchDataRecebimento = watch('dataRecebimento');
  const watchStatus = watch('status');

  useEffect(() => {
    if (watchDataRecebimento) {
      setValue('status', 'RECEBIDO');
    }
  }, [watchDataRecebimento, setValue]);

  useEffect(() => {
    if (valor && valorRecebido < valor && valorRecebido > 0) {
      setValue('status', 'PARCIALMENTE_RECEBIDO');
    }
  }, [setValue, valor, valorRecebido]);

  useEffect(() => {
    if (watchStatus === 'RECEBIDO' && !watchDataRecebimento) {
      setValue('dataRecebimento', new Date());
      setValue('valorRecebido', watchValor);
    }
  }, [watchStatus, watchDataRecebimento, setValue, watchValor]);

  useEffect(() => {
    if (watchStatus === 'PARCIALMENTE_RECEBIDO' && valorRecebido === valor) {
      toast(<Toast />, {
        data: {
          type: 'warning',
          message:
            'Para a opção "Parcialmente recebido", o valor do campo "Valor recebido" deve ser menor que o valor do campo "Valor".',
          title: 'Atenção!',
        },
      });
    }
  }, [valor, valorRecebido, watchStatus]);

  const loadPage = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ContasReceberAPI.getContaReceberById(
        Number(params.id),
      );
      setEditContaReceber(response);
      setValor(response.valor || 0);
      setValorRecebido(response.valorRecebido || 0);
      const {
        profissionalSaude,
        paciente,
        consultorio,
        convenio,
        centroCusto,
        tipoContaReceber,
        dataAtendimento,
        dataRecebimento,
        dataVencimento,
        ...rest
      } = response;
      reset({
        ...rest,
        idConsultorio: consultorio?.id,
        idCentroCusto: centroCusto?.id,
        idTipoContaReceber: tipoContaReceber?.id,
        ...(profissionalSaude && {
          idProfissionalSaude: profissionalSaude?.id,
        }),
        ...(convenio && { idConvenio: convenio?.id }),
        ...(paciente && { idPaciente: paciente?.id }),
        dataVencimento: dataVencimento ? new Date(dataVencimento) : undefined,
        dataRecebimento: dataRecebimento
          ? new Date(dataRecebimento)
          : undefined,
        dataAtendimento: dataAtendimento
          ? new Date(dataAtendimento)
          : undefined,
      });
    } catch (error) {
      console.error(error);
      navigate('/faturamento/contas-receber');
    } finally {
      setLoading(false);
    }
  }, [navigate, params.id, reset]);

  const fetchProfissionais = useCallback(async () => {
    if (!watchConsultorios) {
      return setProfissionaisOptions([]);
    }
    try {
      const response = await PesquisaPacienteAPI.loadProfissionaisConsultorio(
        watchConsultorios,
      );
      setProfissionaisOptions(response.data || []);
      if (isMedico) {
        setValue('idProfissionalSaude', user?.idUsuario);
      }
    } catch (error) {
      console.error(error);
      setProfissionaisOptions([]);
    }
  }, [isMedico, setValue, user?.idUsuario, watchConsultorios]);

  const fetchCentroCusto = useCallback(async () => {
    if (!watchConsultorios) {
      return setOptionsCentroCusto([]);
    }
    try {
      const response: any = await CentroCustoAPI.getCentroCusto({
        consultoriosIds: [watchConsultorios],
      });
      setOptionsCentroCusto(response.list);
    } catch (error) {
      console.error('Error fetching CentroCusto:', error);
      setOptionsCentroCusto([]);
    }
  }, [watchConsultorios]);

  const fetchTipoConta = useCallback(async () => {
    try {
      const response = await TipoContaReceberAPI.getTipoContaReceber({
        ...(watchConsultorios.length > 0 &&
          !watchConsultorios.includes('todos') && {
            idsConsultorios: watchConsultorios.join(','),
          }),
      });

      setTipoContaOptions(response.list);
    } catch (error) {
      console.error(error);
      setTipoContaOptions([]);
    }
  }, [watchConsultorios]);

  useEffect(() => {
    fetchProfissionais();
  }, [fetchProfissionais]);

  useEffect(() => {
    fetchCentroCusto();
  }, [fetchCentroCusto]);

  useEffect(() => {
    fetchTipoConta();
  }, [fetchTipoConta]);

  useEffect(() => {
    if (params.id) {
      loadPage();
    } else {
      setLoading(false);
    }
  }, [loadPage, params.id]);

  const onSubmit = async (data: any) => {
    const {
      servicos,
      dataAtendimento,
      dataRecebimento,
      dataVencimento,
      ...rest
    } = data;

    const formattedServicos = servicos.map((servico: any) => {
      const { servicoTuss, ...restServico } = servico;
      return {
        ...restServico,
        idServicoTuss: servicoTuss?.id,
        servicoAutogerado: servico.servicoAutogerado || false,
      };
    });

    const payload = {
      ...rest,

      servicos: formattedServicos,
      ...(dataAtendimento && {
        dataAtendimento: dataAtendimento.toISOString(),
      }),
      ...(dataRecebimento && {
        dataRecebimento: dataRecebimento.toISOString(),
      }),
      ...(dataVencimento && { dataVencimento: dataVencimento.toISOString() }),
    };

    try {
      if (editContaReceber) {
        await ContasReceberAPI.putContaReceber(payload);
      } else {
        await ContasReceberAPI.postContaReceber(payload);
      }
      return navigate('/faturamento/contas-receber');
    } catch (error) {
      console.error(error);
    }
  };

  const dropDownItemTemplate = useCallback((item: any) => {
    return (
      <SimpleText
        className="p-d-flex p-ai-center p-text-truncate"
        style={{ maxWidth: '10rem' }}
      >
        {item.tipo || item.nome}
      </SimpleText>
    );
  }, []);

  if (loading) return <GridListLoading />;

  return (
    <FormProvider {...useFormMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="form-tipo-conta-pagar"
        className="p-d-grid p-flex-column p-col-12 p-md-7 p-lg-6 p-xl-5"
      >
        <div className=" p-d-flex p-gap-2  p-w-100 p-my-2">
          <CalendarInputControlled
            className=" p-w-100"
            control={control}
            name="dataVencimento"
            label="Data do vencimento"
            showIcon
          />
          <CalendarInputControlled
            className=" p-w-100"
            control={control}
            name="dataRecebimento"
            label="Data do recebimento"
            showIcon
          />
        </div>

        <div className="p-py-2">
          <DropdownControlled
            className=" p-w-100"
            control={control}
            name="credor"
            label="Credor"
            options={[
              { label: 'Consultório', value: 'CONSULTORIO' },
              { label: 'Profissional', value: 'PROFISSIONAL' },
            ]}
          />
        </div>

        <div className="p-py-2">
          <ConsultorioDropdown
            className=" p-w-100"
            idConsultorioEdit={editContaReceber?.idConsultorio}
          />
        </div>

        <div className="p-py-2">
          <DropdownControlled
            control={control}
            name="idCentroCusto"
            label="Centro de custo"
            options={optionsCentroCusto || []}
            optionLabel="nome"
            optionValue="id"
            className=" p-w-100"
            disabled={!watchConsultorios || !optionsCentroCusto}
            itemTemplate={dropDownItemTemplate}
          />
        </div>

        <div className="p-py-2">
          <DropdownControlled
            control={control}
            name="idTipoContaReceber"
            label="Tipo de conta a receber"
            options={tipoContaOptions || []}
            optionLabel="tipo"
            optionValue="id"
            className=" p-w-100"
            disabled={!watchConsultorios || !tipoContaOptions}
            itemTemplate={dropDownItemTemplate}
          />
        </div>

        <div className="p-py-2">
          <FormInput
            name="descricao"
            label="Descrição"
            className=" p-w-100"
            maxLength={255}
          />
        </div>

        <div className=" p-d-flex p-gap-2  p-w-100 p-py-2">
          <div className="p-d-flex p-flex-column p-gap-2 p-w-100">
            <SimpleText fontSize="xs" medium>
              Valor do serviço
            </SimpleText>
            <InputNumber
              placeholder="R$"
              mode="currency"
              locale="pt-BR"
              format={true}
              allowEmpty={false}
              max={9999999.99}
              value={valor}
              onBlur={e => {
                setValue('valor', convertCurrencyToNumber(e.target.value));
              }}
              onChange={e => setValor(e.target.value)}
            />
            {errors.valor?.message && (
              <SimpleText className={'error p-col-12'} fontSize="xxxs">
                {errors.valor?.message}
              </SimpleText>
            )}
          </div>
          <div className="p-d-flex p-flex-column p-gap-2 p-w-100">
            <SimpleText fontSize="xs" medium>
              Valor recebido
            </SimpleText>
            <InputNumber
              placeholder="R$"
              mode="currency"
              locale="pt-BR"
              format={true}
              allowEmpty={false}
              max={9999999.99}
              value={valorRecebido}
              onBlur={e => {
                setValue(
                  'valorRecebido',
                  convertCurrencyToNumber(e.target.value),
                );
              }}
              onChange={e => setValorRecebido(e.target.value)}
              errorMsg={errors.valorRecebido?.message}
            />
          </div>
        </div>

        <div className="p-mt-1 p-mb-4">
          <SimpleText className="p-col-12 p-px-0 p-py-2" medium>
            Status
          </SimpleText>
          {errors.status?.message && (
            <SimpleText className={'error p-col-12'} fontSize="xxxs">
              {errors.status?.message}
            </SimpleText>
          )}
          <div className="p-my-2 p-d-flex p-gap-2">
            <RadioButtonControlled
              control={control}
              name="status"
              value="A_RECEBER"
              label="A receber"
            />
            <RadioButtonControlled
              control={control}
              name="status"
              value="RECEBIDO"
              label="Recebido"
            />
            <RadioButtonControlled
              control={control}
              name="status"
              value="PARCIALMENTE_RECEBIDO"
              label="Parcialmente recebido"
            />
          </div>
        </div>

        <SimpleText className="p-col-12 p-px-0" fontColor="color_60">
          Os campos abaixo são de preenchimento opcional
        </SimpleText>

        <div className="p-my-2">
          <CalendarInputControlled
            className=" p-w-100"
            control={control}
            name="dataAtendimento"
            label="Data do atendimento"
            showIcon
          />
        </div>

        <div className="p-my-2">
          <FieldSearchPaciente
            className=" p-w-100 "
            idValue
            name="idPaciente"
          />
        </div>

        <div className=" p-d-flex p-gap-2 p-w-100 p-my-3">
          <div className="p-w-100">
            <FieldProfissionais
              name="idProfissionalSaude"
              label="Profissional"
              className=" p-w-100"
              options={profissionaisOptions}
              loading={loading}
              isMedico={isMedico}
              multiple={false}
            />
          </div>
          <div className="p-w-100">
            <FieldConvenio
              name="idConvenio"
              label="convênio"
              profissionais={profissionaisOptions}
              conveniosListAPI={conveniosListAPI}
              setConveniosListAPI={setConveniosListAPI}
              className="p-w-100"
              multiple={false}
            />
          </div>
        </div>

        <div className="p-col-12 p-md-7 p-lg-6 p-xl-5 p-py-0">
          <Separator layout="horizontal" />
        </div>

        <ServicosTuss />

        <div className="p-col-12 p-md-5 p-lg-4 p-my-2">
          <Button
            type="submit"
            label="Salvar"
            disabled={!isDirty}
            loading={isSubmitting}
            stretch
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default ContasReceberCreateForm;
