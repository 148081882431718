import { useContext, useEffect, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import ThemeContext from 'src/core/themes/ThemeContext';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Tab from 'src/components/Tab/Tab';

import RelatoriosAgenda from './RelatoriosAgenda/RelatoriosAgenda';
import RelatoriosAtendimentos from './RelatoriosAtendimentos/RelatoriosAtendimentos';
import RelatoriosEmail from './RelatoriosEmail/RelatoriosEmail';
import RelatoriosEmailsAssinatura from './RelatoriosEmailsAssinatura/RelatoriosEmailsAssinatura';
import RelatoriosParto from './RelatoriosParto/RelatoriosParto';
import RelatoriosTrilhaAuditoria from './RelatoriosTrilhaAuditoria/RelatoriosTrilhaAuditoria';

import './Relatorios.scss';

const Relatorios = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);

  const dispatch = useAppDispatch();

  const options = useMemo(() => {
    return [
      {
        label: 'Agenda',
        path: '/relatorios/agenda',
        content: <RelatoriosAgenda />,
        checkPermission: 'ADM_REL_AGENDA',
      },
      {
        label: 'Atendimentos',
        path: '/relatorios/atendimentos',
        content: <RelatoriosAtendimentos />,
        checkPermission: 'ADM_REL_ATENDIMENTO',
      },
      {
        label: 'E-mails telemedicina',
        path: '/relatorios/e-mails-telemedicina',
        content: <RelatoriosEmail />,
        checkPermission: 'ADM_HISTORICO_EMAIL_TELEMEDICINA',
      },
      {
        label: 'E-mails assinatura',
        path: '/relatorios/emails-assinatura',
        content: <RelatoriosEmailsAssinatura />,
        checkPermission: 'ADM_HISTORICO_EMAILS_DOC_ASSINADOS',
      },
      {
        label: 'Trilha de auditoria',
        path: '/relatorios/trilha-auditoria',
        content: <RelatoriosTrilhaAuditoria />,
        checkPermission: 'ADM_HISTORICO_EMAILS_DOC_ASSINADOS',
      },
      {
        label: 'Relatórios de parto',
        path: '/relatorios/relatorios-parto',
        content: <RelatoriosParto />,
        checkPermission: 'ADM_REL_PREVISAO_PARTO',
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(UsuarioAPI.loadUtils());
  }, [dispatch]);

  const tabs = useMemo((): any[] => {
    return options.filter(({ checkPermission }) =>
      authorities?.includes(checkPermission),
    );
  }, [authorities, options]);

  useEffect(() => {
    if (location.pathname === '/relatorios') {
      navigate(tabs[0].path || '/');
    }
  }, [location.pathname, navigate, tabs]);

  const initialTabIndex = useMemo(
    () => tabs.findIndex(tab => tab.path === location.pathname),
    [location.pathname, tabs],
  );

  return (
    <Page className={`Relatorios ${theme?.theme || ''}`}>
      <>
        <PageHeader
          title={'Relatórios'}
          subtitle={
            'Obtenha os resultados parciais ou totais para a atividade desejada'
          }
        />
        <Page content white>
          <Tab
            clean
            values={tabs}
            initialTabIndex={initialTabIndex}
            setActiveIndex={initialTabIndex}
            mode="path"
          />
        </Page>
      </>
    </Page>
  );
};

export default Relatorios;
