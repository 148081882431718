import { useState } from 'react';

import { useNavigate } from 'react-router';

import { ContasReceberAPI } from 'src/APIs/FinanceiroAPI/ContasReceberAPI/ContasReceberAPI';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import CheckboxCounter from './components/CheckboxCounter';
import Button from 'src/components/Basics/Button/Buttons';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import ModalAlterarStatus from '../ModalAlterarStatus';
import { useSelectedContaReceber } from '../useSelectedContaReceber';

import Filter from './Filter';
import ListHeader from './Rows/ListHeader';
import Row from './Rows/Row';

const List = () => {
  const [habilitarBotoesRelatorio, setHabilitarBotoesRelatorio] =
    useState(false);

  const navigate = useNavigate();

  const { selectedContaReceber, reload } = useSelectedContaReceber();

  const { isMobile, isTablet } = useSize();

  const modalAlterarStatusDisclosure = useDisclosure({ opened: false });

  const desktopSize = !isMobile && !isTablet;

  const fetchApi = async (params: any) => {
    const response = await ContasReceberAPI.getContasReceber(params);

    if (response?.list?.length > 0) {
      setHabilitarBotoesRelatorio(true);
    }
    return response;
  };

  const renderRow = (item: IContaReceberItem): JSX.Element => {
    return <Row key={item.id} item={item} />;
  };

  //TODO: Implementar geração de relatório

  // const gerarRelatorio = async (type?: string) => {
  //   if (!filtroPesquisa) return;

  //   if (type === 'pdf') {
  //     const response = await ContasReceberAPI.getRelatorioPdf(filtroPesquisa);
  //     handleDownloadPDF(response, 'relatorio-conta-receber');
  //   }

  //   if (type === 'csv') {
  //     const response = await ContasReceberAPI.getRelatorioExcel(filtroPesquisa);
  //     return handleDownloadCSV(response, 'relatorio-conta-receber');
  //   }

  //   const response = await ContasReceberAPI.getRelatorioPdf(filtroPesquisa);
  //   handleOpenPDF(response, 'relatorio-conta-receber');
  // };

  return (
    <div className="p-p-1">
      <SearchList.Root
        fetchApi={fetchApi}
        consultorioRequired
        reloadWhenConsultorioChange
      >
        <Filter />
        <SearchList.BorderContainer className="p-py-1 p-px-3">
          <div className="p-d-flex p-jc-between p-ai-center p-p-2">
            <Button
              className="p-mb-2"
              label="Nova conta a receber"
              icon="pi pi-plus"
              iconPos="left"
              btnType="pill"
              onClick={() => navigate('/faturamento/contas-receber/novo')}
              stretch={isMobile}
              checkPermission="ADM_CONTAS_RECEBER_CADASTRAR"
            />

            <Button
              type="button"
              label="Alterar status geral"
              disabled={selectedContaReceber?.length === 0}
              onClick={() => {
                modalAlterarStatusDisclosure.open();
              }}
              checkPermission={'ADM_CONTAS_RECEBER_ALTERAR'}
            />
          </div>
          <CheckboxCounter />
          {desktopSize && <ListHeader />}
          <SearchList.InfiniteScroll reload={reload} renderRow={renderRow} />
        </SearchList.BorderContainer>
      </SearchList.Root>
      <div className="p-mt-3">
        {/* <div>
          <SimpleText fontColor='color_40'>
            Para concluir, gere o relatório optando pelo formato.
          </SimpleText>
        </div>
        <div className="p-d-flex p-mt-1">
          <Button
            className="p-mr-2"
            type="button"
            label="Gerar relatório"
            btnType={BtnTypes.TONAL}
            onClick={() => {
              gerarRelatorio();
            }}
            disabled={!habilitarBotoesRelatorio}
          />
          <Button
            className="p-mr-2"
            type="button"
            label="Gerar Excel"
            btnType={BtnTypes.TONAL}
            onClick={() => {
              gerarRelatorio('csv');
            }}
            disabled={!habilitarBotoesRelatorio}
          />
          <Button
            className="p-mr-2"
            type="button"
            label="Baixar PDF"
            btnType={BtnTypes.TONAL}
            onClick={() => {
              gerarRelatorio('pdf');
            }}
            disabled={!habilitarBotoesRelatorio}
          />
        </div> */}
      </div>
      {modalAlterarStatusDisclosure.isOpen && (
        <ModalAlterarStatus {...modalAlterarStatusDisclosure} />
      )}
    </div>
  );
};

export default List;
