import React, { useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';
import FileUploadAPI from 'src/APIs/StorageAPI/FileUpload/FileUploadAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import Dialog from 'src/components/Dialog/Dialog';

import { saveDadosPessoais } from '../../helpers';

interface AnexarConsentimentoDialogProps extends DisclosureType {
  loadDadosPessoais: () => void;
  prevData: any;
}

const AnexarConsentimentoDialog = ({
  close,
  loadDadosPessoais,
  prevData,
}: AnexarConsentimentoDialogProps) => {
  const { idEmpresa } = useAppSelector(state => state.user);
  const { idPaciente } = useParams();

  const [arquivoAnexo, setArquivoAnexo] = useState<File | null>(null);
  const [filePath, setFilePath] = useState<string | null>('');
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleSelectFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setArquivoAnexo(file);
      try {
        setLoading(true);
        const dataFile: FileUpload = {
          fileName: file?.name,
          contentType: file?.type,
          contentLength: file?.size,
          resourceType: 'OUTROS',
          resourceId: idEmpresa,
        };
        const responseGoogleStorage = await FileUploadAPI.sendFileUpload(
          dataFile,
          { hideToast: true, throwError: true },
        );
        const urlGoogle = responseGoogleStorage?.data?.uploadUrl;

        const response: any = await axios.put(urlGoogle, file, {
          headers: {
            'Content-Type': file?.type,
          },
        });
        if (response?.status === 200) {
          return setFilePath(responseGoogleStorage?.data?.filePath);
        }
        return toast(<Toast />, {
          data: {
            title: 'Erro ao enviar arquivo',
            message:
              'Ocorreu um erro ao enviar o arquivo para o servidor. Tente novamente.',
            type: 'error',
          },
        });
      } catch (error) {
        console.error('Error converting file to Base64:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleExcluirArquivo = () => {
    setArquivoAnexo(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validationSchema = Yup.object().shape({
    termoConsentimentoDataOcorrencia: Yup.date()
      .required('Campo obrigatório')
      .typeError('Data inválida'),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const payload = {
      ...prevData,
      idEmpresa,
      termoConsentimentoFilePath: filePath,
      termoConsentimentoFileName: arquivoAnexo?.name,
      termoConsentimentoFileSize: arquivoAnexo?.size,
      ...data,
    };

    try {
      await saveDadosPessoais(Number(idPaciente), idEmpresa, payload);

      loadDadosPessoais();
      close();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      id="anexar-consentimento-dialog"
      header="Enviar Arquivo"
      onHide={close}
      visible={true}
      maximizedMobileSize
      style={{ width: '100%', maxWidth: '500px' }}
    >
      <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
        <SimpleText className="p-col-12" fontColor="color_60">
          Anexe ao sistema o documento do consentimento do paciente enviando do
          seu dispositivo
        </SimpleText>

        <CalendarInputControlled
          name="termoConsentimentoDataOcorrencia"
          control={control}
          label="Data da ocorrência"
          dateFormat="dd/mm/yy"
          className="p-col-12"
          showIcon
        />

        <div className="p-d-flex p-flex-column p-gap-2 p-px-2">
          <Button
            type="button"
            label="Escolher arquivo"
            btnType="tonal"
            onClick={() => handleSelectFile()}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".pdf, image/*"
            onChange={handleFileChange}
          />
          <SimpleText fontColor="color_60" fontSize="xs">
            Formatos aceitos: PDF e imagem
          </SimpleText>
          {arquivoAnexo && (
            <div className="p-d-flex p-ai-center p-gap-2 arquivo-anexo">
              <SimpleText
                className=" p-d-flex p-ai-center p-gap-1 border-arquivo"
                fontColor="color_60"
                ellipsisOverflow
              >
                <i className="fas fa-file" /> {arquivoAnexo.name}
              </SimpleText>
              <Button
                btnType="danger-link"
                label="Excluir"
                onClick={() => handleExcluirArquivo()}
              />
            </div>
          )}
        </div>

        <div className="p-grid p-col-12 p-px-0">
          <div className="p-col-6">
            <Button
              type="button"
              label="Cancelar"
              btnType="ghost"
              onClick={() => close()}
              stretch
            />
          </div>
          <div className="p-col-6">
            <Button
              type="submit"
              label="Salvar"
              stretch
              disabled={!arquivoAnexo}
              loading={loading || isSubmitting}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default AnexarConsentimentoDialog;
